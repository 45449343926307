<template>
    <div class="overview">
        <div class="tab">
            <div class="block" v-if="Untill">
                <span class="title">Scan aanmaken</span>
                <span class="description" v-if="BBM == 'ON'">Start een nieuwe toetsing voor een (nieuwe) opdrachtgever waarvan nog geen inkomsten zijn opgehaald.</span>
                <span class="description" v-else-if="BBM == 'OG'">Start een nieuwe toetsing voor een (nieuwe) ZZPer welke je wilt toetsen voor je opdracht.</span>
                <router-link to="/opdrachten/aanmaken" class="button">Nieuwe opdracht scan starten</router-link>
            </div>
            <div class="block" v-else>
                <span class="title">Lidmaatschap verlopen</span>
                <span class="description">Je lidmaatschap is verlopen. Deze dient verlengt te worden om een nieuwe opdracht scan uit te voeren. Tot die tijd kan je natuurlijk wel gewoon je oude scans openen. Klik op de knop hieronder om naar de instellingen te gaan waar je je lidmaatschap kan verlengen.</span>
                <router-link to="/instellingen/lidmaatschap" class="button">Ga naar instellingen</router-link>
            </div>
            <div class="block" v-if="Assessments.length > 0">
                <span class="title">Overzicht scans</span>
                <DataTable class="table" v-model:filters="Filters" :value="Assessments" selectionMode="single" dataKey="Identifier" sortField="Created" :sortOrder="-1" scrollHeight="flex" sortMode="single" scrollable showGridlines stripedRows paginator :rows="100" :rowsPerPageOptions="[25, 50, 75, 100]" :globalFilterFields="['Name']" :loading="Loader" @rowSelect="OpenAssessment($event), this.$root.Vibrate()">
                    <template #header>
                        <input class="search" type="text" v-model="Filters.global.value" placeholder="Zoek scans...">
                    </template>
                    <template v-if="!Loader" #empty>Geen scans gevonden</template>
                    <template #loading>Scans aan het laden...</template>
                    <Column field="Name" header="Naam" sortable></Column>
                    <Column field="Score" header="Score" sortable>
                        <template #body="slotProps">
                            {{ slotProps.data.Score }} / 100
                        </template>
                    </Column>
                    <Column field="Created" header="Datum" sortable>
                        <template #body="slotProps">
                            {{ this.$moment(slotProps.data.Created).format('DD-MM-Y') }}
                        </template>
                    </Column>
                    <Column header="Export">
                        <template #body="slotProps">
                            <span class="export" @click="Export(slotProps.data.Identifier), this.$root.Vibrate()">Export</span>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { Browser } from '@capacitor/browser';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

const toast = useToast()

export default {
    name: 'Overview',

    components: {
        DataTable,
        Column
    },

    data () {
        return {
            Loader: false,
            Assessments: [],
            Untill: false,
            Filters: {
                global: {
                    value: ''
                }
            },
            BBM: localStorage.getItem('BBM')
        }
    },

    mounted () {
        this.GetItems()
    },

    methods: {
        GetItems () {
            this.$root.SetLoader(true)
            this.$http.get(`https://bouwbond.app/api/v1/assessments`).then((response) => {
                if(response.data.assessments) { this.Assessments = response.data.assessments }
                if(response.data.untill) { this.Untill = response.data.untill }

                this.$root.SetLoader(false)
            })
        },

        OpenAssessment (e) {
            this.$router.push('/opdrachten/' + e.data.Identifier)
        },

        Export (e) {
            this.$root.SetLoader(true)
            this.$http.get(`https://bouwbond.app/api/v1/assessments/${e}/export`).then((response) => {
                this.$root.SetLoader(false)

                if(response.data.token) {
                    this.OpenExport(response.data.token)
                } else {
                    toast.warning("Export niet gevonden. Probeer het nog een keer.", {
                        position: "top-right",
                        timeout: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        icon: true
                    })
                }
            })
        },

        async OpenExport (token) {
            if(this.$root.Platform == 'web') {
                setTimeout(() => {
                    window.open('https://bouwbond.app/api/v1/export/' + token, '_blank').focus()
                })
            } else {
                await Browser.open({ 
                    url: 'https://bouwbond.app/api/v1/export/' + token,
                    presentationStyle: 'popover'
                })
            }
        }
    }
}
</script>

<style scoped>
.overview .tab {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: calc(100% - 50px);
    gap: 50px;
    z-index: 1;
}

.overview .tab .block {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 35px 25px 50px 25px;
    background: #fff;
    border: solid 1px #eff0f6;
    border-radius: 5px;
}

.overview .tab .block .title {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    font-size: 30px;
}

.overview .tab .block .description {
    width: 100%;
}

.overview .tab .block .button {
    margin: 25px 0 0 0;
    padding: 15px 25px;
    background: #009fe3;
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    border-radius: 5px;
}

.overview .tab .block .table {
    width: 100%;
    padding: 0 0 25px 0;
}

.overview .tab .block .table:deep(.p-datatable-header input) {
    width: 100%;
    padding: 5px;
    border: 0;
    outline: 0;
}

.overview .tab .block .table:deep(.p-datatable-paginator-bottom) {
    border: 0;
}

.overview .tab .block .table:deep(.export) {
    color: #009fe3;
    text-decoration: underline;
    text-underline-offset: 5px;
}
</style>