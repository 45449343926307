<template>
    <div class="overview">
        <div class="tab">
            <div class="block" v-if="Untill">
                <span class="title">Nieuwe check starten</span>
                <span>Krijg meer gevoel bij je huidige situatie als ZZP-er en doe de status check. Heb je deze al uitgevoerd en heb je zaken aangepast? Start dan een nieuwe en zie hoe je score verbetert!</span>
                <router-link to="/checks/aanmaken" class="button">Start een nieuwe ZZP check</router-link>
            </div>
            <div class="block" v-else>
                <span class="title">Lidmaatschap verlopen</span>
                <span class="description">Je lidmaatschap is verlopen. Deze dient verlengt te worden om een nieuwe opdracht scan uit te voeren. Tot die tijd kan je natuurlijk wel gewoon je oude scans openen. Klik op de knop hieronder om naar de instellingen te gaan waar je je lidmaatschap kan verlengen.</span>
                <router-link to="/instellingen/lidmaatschap" class="button">Ga naar instellingen</router-link>
            </div>
            <div class="block" v-if="Checks.length > 0">
                <span class="title">Overzicht scans</span>
                <DataTable class="table" :value="Checks" selectionMode="single" dataKey="Identifier" sortField="Created" :sortOrder="-1" scrollHeight="flex" sortMode="single" scrollable showGridlines stripedRows paginator :rows="100" :rowsPerPageOptions="[25, 50, 75, 100]" :loading="Loader" @rowSelect="OpenCheck($event), this.$root.Vibrate()">
                    <template v-if="!Loader" #empty>Geen checks gevonden</template>
                    <template #loading>Checks aan het laden...</template>
                    <Column field="Score" header="Score" sortable>
                        <template #body="slotProps">
                            {{ slotProps.data.Score }} / 100
                        </template>
                    </Column>
                    <Column field="Created" header="Datum" sortable>
                        <template #body="slotProps">
                            {{ this.$moment(slotProps.data.Created).format('DD-MM-Y') }}
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default {
    name: 'Overview',

    components: {
        DataTable,
        Column
    },

    data () {
        return {
            Checks: [],
            Untill: false
        }
    },

    mounted () {
        this.GetChecks()
    },

    methods: {
        GetChecks () {
            this.$root.SetLoader(true)
            this.$http.get(`https://bouwbond.app/api/v1/checks`).then((response) => {
                if(response.data.checks) { this.Checks = response.data.checks }
                if(response.data.untill) { this.Untill = response.data.untill }

                this.$root.SetLoader(false)
            })
        },

        OpenCheck (e) {
            this.$router.push('/checks/' + e.data.Identifier)
        }
    }
}
</script>

<style scoped>
.overview .tab {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: calc(100% - 50px);
    gap: 50px;
    z-index: 1;
}

.overview .tab .block {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 35px 25px 50px 25px;
    background: #fff;
    border: solid 1px #eff0f6;
    border-radius: 5px;
}

.overview .tab .block .title {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    font-size: 30px;
}

.overview .tab .block .button {
    margin: 25px 0 0 0;
    padding: 15px 25px;
    background: #009fe3;
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    border-radius: 5px;
}

.overview .tab .block .table {
    width: 100%;
    padding: 0 0 25px 0;
}

.overview .tab .block .table:deep(.p-datatable-header input) {
    width: 100%;
    padding: 5px;
    border: 0;
    outline: 0;
}

.overview .tab .block .table:deep(.p-datatable-paginator-bottom) {
    border: 0;
}
</style>