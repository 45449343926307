<template>
    <div class="settings">
        <div class="settings-menu" v-if="Client.KVK">
            <span class="menu-item" @click="View = 'informatie', this.$root.Vibrate()" :class="{ active: View == 'informatie' }">Informatie</span>
            <span class="menu-item" @click="View = 'account', this.$root.Vibrate()" :class="{ active: View == 'account' }">Account</span>
            <span class="menu-item" @click="View = 'lidmaatschap', this.$root.Vibrate()" :class="{ active: View == 'lidmaatschap' }">Lidmaatschap</span>
        </div>
        <div class="tab" v-if="View == 'informatie' && Client.KVK">
            <span class="title">Bedrijfsgegevens</span>
            <div class="group">
                <span class="subtitle">Bedrijfsnaam</span>
                <input type="text" :value="Client.Name" disabled>
            </div>
            <div class="group">
                <span class="subtitle">Kamer van Koophandel</span>
                <input type="text" :value="Client.KVK" disabled>
            </div>
            <div class="group">
                <span class="subtitle">Adres</span>
                <input type="text" :value="Client.Street + ' ' + Client.Number" disabled>
            </div>
            <div class="group">
                <span class="subtitle"></span>
                <input type="text" :value="Client.Postcode + ' ' + Client.City" disabled>
            </div>
            <div class="group">
                <span class="subtitle"></span>
                <input type="text" :value="Client.Country" disabled>
            </div>
        </div>
        <div class="tab" v-if="View == 'informatie' && !Client.KVK">
            <div class="create" v-if="KvK.Active">
                <span class="title">Kamer van Koophandel</span>
                <span class="description">Om te beginnen met ons platform willen we graag wat zaken van je weten. Laten we beginnen met je bedrijf. Zoek hieronder de naam op van je onderneming zoals hij staat ingeschreven bij de Kamer van Koophandel.</span>
                <div class="group">
                    <span class="subtitle">Naam</span>
                    <input type="text" class="interaction" v-model="KvK.Search" @keydown.enter="SearchKvK">
                    <button @click="SearchKvK(), this.$root.Vibrate()" :class="{ disabled: KvK.Loader || !KvK.Search }">Zoeken</button>
                </div>
            </div>
            <div class="create" v-if="KvK.Active && KvK.Items.length > 0">
                <span class="title">Zoekresultaten</span>
                <span class="description">Hieronder zie je de resultaten in het handelsregister van de Kamer van Koophandel. Selecteer je onderneming in de resultaten. We tonen maximaal 50 inschrijvingen.</span>
                <input v-if="KvK.Items.length > 10" type="text" class="search" placeholder="Zoeken in de resultaten..." v-model="KvK.Filters.global.value">
                <DataTable v-model:filters="KvK.Filters" :value="KvK.Items" paginator :rows="10" :rowsPerPageOptions="[10, 25, 50]" stripedRows sortable sortField="Name" :sortOrder="1" selectionMode="single" dataKey="Identifier" @rowSelect="CompleteKvK" :globalFilterFields="['Name', 'Street', 'City']">
                    <Column field="Name" header="Naam"></Column>
                    <Column field="ID" header="KVK"></Column>
                    <Column field="Street" header="Straat"></Column>
                    <Column field="City" header="Plaats"></Column>
                </DataTable>
            </div>
            <div class="create" v-if="!KvK.Active && KvK.ID">
                <span class="title">Controleer je gegevens</span>
                <span class="description">We hebben je gegevens opgehaald bij de Kamer van Koophandel. Controleer de gegevens voordat je verdergaat. Mochten de gegevens afwijken, dien je deze eerst bij de Kamer van Koophandel te wijzigen.</span>
                <div class="group">
                    <span class="subtitle" v-if="KvK.Name && KvK.Name.length == 1">Handelsnaam</span>
                    <span class="subtitle" v-if="KvK.Name && KvK.Name.length > 1">Handelsnamen</span>
                    <input v-for="(Name, i) in KvK.Name" :key="i" type="text" :value="Name.naam" disabled>
                </div>
                <div class="group">
                    <span class="subtitle">Kamer van Koophandel</span>
                    <input type="text" :value="KvK.ID" disabled>
                </div>
                <div class="group half">
                    <span class="subtitle">Straat</span>
                    <input type="text" :value="KvK.Street" disabled>
                </div>
                <div class="group half">
                    <span class="subtitle">Huisnummer</span>
                    <input type="text" :value="KvK.HouseNumber" disabled>
                </div>
                <div class="group half">
                    <span class="subtitle">Postcode</span>
                    <input type="text" :value="KvK.Postcode" disabled>
                </div>
                <div class="group half">
                    <span class="subtitle">Plaats</span>
                    <input type="text" :value="KvK.City" disabled>
                </div>
                <div class="group">
                    <span class="subtitle">Land</span>
                    <input type="text" :value="KvK.Country" disabled>
                </div>
                <div class="group">
                    <span class="subtitle" v-if="KvK.SBI && KvK.SBI.length == 1">SBI Code</span>
                    <span class="subtitle" v-if="KvK.SBI && KvK.SBI.length > 1">SBI Codes</span>
                    <input v-for="(SBI, i) in KvK.SBI" :key="i" type="text" :value="SBI.sbiCode + ' - ' + SBI.sbiOmschrijving" disabled>
                </div>

                <span class="title marginTop">Contactgegevens</span>
                <span class="description">We hebben je contactgegevens nodig voor het gebruik van ons portaal en om contact met je op te kunnen nemen bij vragen of problemen.</span>
                <div class="group third">
                    <span class="subtitle">Voornaam *</span>
                    <input type="text" v-model="User.Firstname">
                </div>
                <div class="group third">
                    <span class="subtitle">Tussenvoegsels</span>
                    <input type="text" v-model="User.Middlename">
                </div>
                <div class="group third">
                    <span class="subtitle">Achternaam *</span>
                    <input type="text" v-model="User.Lastname">
                </div>
                <div class="group half">
                    <span class="subtitle">Telefoonnummer *</span>
                    <input type="text" v-model="User.Phone">
                </div>
                <div class="group half">
                    <span class="subtitle">E-mailadres *</span>
                    <input type="text" :value="User.Email" disabled>
                </div>
                <div class="group">
                    <button :class="{ disabled: !User.Firstname || !User.Lastname || !/^[0-9]{10,10}$/g.test(User.Phone) || !User.Email }" @click="ConnectKvK(), this.$root.Vibrate()">Koppelen</button>
                </div>
            </div>
        </div>
        <div class="tab" v-if="View == 'account'">
            <span class="title">Login</span>
            <div class="group">
                <span class="subtitle">E-mailadres</span>
                <input type="text" :value="User.Email" disabled>
                <svg class="edit" @click="User.EmailChange = !User.EmailChange, this.$root.Vibrate()" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
            </div>
            <div class="change" v-if="User.EmailChange">
                <div class="group">
                    <span class="subtitle">Nieuw e-mailadres</span>
                    <input type="email" v-model="NewEmail">
                </div>
                <div class="group">
                    <span class="subtitle">Nieuw e-mailadres (controle)</span>
                    <input type="email" v-model="NewEmailCheck">
                </div>
                <div class="group" v-if="NewEmailOTP">
                    <span class="subtitle">Tweestapsverificatie</span>
                    <InputOtp v-model="NewEmailOTPCode" :length="6" />
                </div>
                <span class="save" @click="ChangeEmail(), this.$root.Vibrate()" v-if="(NewEmail == NewEmailCheck) && ((/^\S+@\S+\.\S+$/).test(NewEmail))">Wijzigen</span>
            </div>
            <div class="group">
                <span class="subtitle">Wachtwoord</span>
                <input type="password" value="********" disabled>
                <svg class="edit" @click="User.PasswordChange = !User.PasswordChange, this.$root.Vibrate()" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
            </div>
            <div class="change" v-if="User.PasswordChange">
                <div class="group">
                    <span class="subtitle">Oude wachtwoord</span>
                    <Password v-model="OldPassword" placeholder="Je oude wachtwoord" :feedback="false" />
                </div>
                <div class="group">
                    <span class="subtitle">Nieuw wachtwoord</span>
                    <Password v-model="NewPassword" placeholder="Je nieuwe wachtwoord" promptLabel="Je nieuwe wachtwoord moet voldoen aan:" weakLabel="Zwak" mediumLabel="Gemiddeld" strongLabel="Sterk" toggleMask>
                        <template #footer>
                            <div class="validate">
                                <li v-if="NewPassword.match(/[A-Z]/g)" style="color: #009fe3; font-weight: 600;">Hoofdletters</li>
                                <li v-else style="color: red;">Hoofdletters</li>

                                <li v-if="NewPassword.match(/[a-z]/g)" style="color: #009fe3; font-weight: 600;">Lage letters</li>
                                <li v-else style="color: red;">Lage letters</li>

                                <li v-if="NewPassword.match(/[0-9]/g)" style="color: #009fe3; font-weight: 600;">Cijfers</li>
                                <li v-else style="color: red;">Cijfers</li>

                                <li v-if="NewPassword.length >= 8" style="color: #009fe3; font-weight: 600;">Minimaal 8 tekens</li>
                                <li v-else style="color: red;">Minimaal 8 tekens</li>
                            </div>
                        </template>
                    </Password>
                </div>
                <div class="group">
                    <span class="subtitle">Nieuw wachtwoord (controle)</span>
                    <Password v-model="NewPasswordCheck" placeholder="Herhaal je nieuwe wachtwoord" :feedback="false" />
                </div>
                <div class="group" v-if="NewPasswordOTP">
                    <span class="subtitle">Tweestapsverificatie</span>
                    <InputOtp v-model="NewPasswordOTPCode" :length="6" />
                </div>
                <span class="save" @click="ChangePassword(), this.$root.Vibrate()" v-if="((NewPassword.match(/[A-Z]/g) && NewPassword.match(/[a-z]/g) && NewPassword.match(/[0-9]/g) && NewPassword.length >= 8) && NewPassword == NewPasswordCheck)">Wijzigen</span>
            </div>
            <span class="title marginTop">Tweestapsverificatie</span>
            <!-- <div class="group">
                <span class="subtitle">Authenticator  <span v-if="User.TFA == 'Authenticator'" class="active">Actief</span> <span class="tip">tip</span></span>
                <div class="toggle">
                    <RadioButton v-model="NewTFA" name="TFA" value="Authenticator" />
                </div>
            </div> -->
            <div class="group">
                <span class="subtitle">E-mail</span>
                <div class="toggle">
                    <RadioButton v-model="NewTFA" name="TFA" value="Email" />
                </div>
            </div>
            <!-- <div class="group">
                <span class="subtitle">Uitgeschakeld</span>
                <div class="toggle">
                    <RadioButton v-model="NewTFA" name="TFA" value="" />
                </div>
            </div> -->
            <div class="group otp" v-if="NewTFAOTP">
                <span class="subtitle">Tweestapsverificatie</span>
                <span class="description" v-if="User.TFA == 'Authenticator'">Om de wijziging op te slaan dien je de code van je Authenticator app in te voeren ter controle.</span>
                <span class="description" v-if="User.TFA == 'Email'">We hebben je een e-mail gestuurd met een code welke je dient in te voeren ter controle.</span>
                <InputOtp v-model="NewTFAOTPCode" :length="6" />
            </div>
            <div class="group column otp" v-if="NewTFAQR">
                <span class="subtitle">Scan onderstaande code met je Authenticator App en voer de code in</span>
                <img :src="NewTFAQR" alt="Scan QR" width="300" height="300">
                <InputOtp v-model="NewTFAQRCode" :length="6" />
            </div>
            <div class="group" v-if="NewTFA !== User.TFA">
                <span class="save-otp" @click="ChangeOTP(), this.$root.Vibrate()">Wijzigen</span>
            </div>
        </div>
        <div class="tab" v-if="View == 'lidmaatschap'">
            <span class="title">Lidmaatschap</span>
            <div class="activate" v-if="Untill">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 10V13" stroke-width="2" stroke-linecap="round"/><path d="M12 16V15.9888" stroke-width="2" stroke-linecap="round"/><path d="M10.2518 5.147L3.6508 17.0287C2.91021 18.3618 3.87415 20 5.39912 20H18.6011C20.126 20 21.09 18.3618 20.3494 17.0287L13.7484 5.147C12.9864 3.77538 11.0138 3.77538 10.2518 5.147Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <span class="text">Je lidmaatschap is actief tot <b>{{ this.$moment(Client.Untill).format('DD-MM-YYYY') }}</b>. Na deze periode is het niet meer mogelijk om nieuwe scans te maken. Je ontvangt automatisch een e-mail om je lidmaatschap op tijd te verlengen. Klik op de knop hieronder om de periode met 12 maanden te verlengen.</span>
            </div>
            <div class="activate" v-else>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 10V13" stroke-width="2" stroke-linecap="round"/><path d="M12 16V15.9888" stroke-width="2" stroke-linecap="round"/><path d="M10.2518 5.147L3.6508 17.0287C2.91021 18.3618 3.87415 20 5.39912 20H18.6011C20.126 20 21.09 18.3618 20.3494 17.0287L13.7484 5.147C12.9864 3.77538 11.0138 3.77538 10.2518 5.147Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <span class="text">Je lidmaatschap is verlopen. Klik hieronder op de knop om je lidmaatschap met 12 maanden te verlengen. Tot die tijd kan je natuurlijk gewoon je reeds gemaakte scans inzien.</span>
            </div>
            <span class="button" @click="Order(), this.$root.Vibrate()">Nieuwe periode aanschaffen</span>    
            
            <span class="title">Periodes</span>
            <div class="activate" v-if="!Active">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 10V13" stroke-width="2" stroke-linecap="round"/><path d="M12 16V15.9888" stroke-width="2" stroke-linecap="round"/><path d="M10.2518 5.147L3.6508 17.0287C2.91021 18.3618 3.87415 20 5.39912 20H18.6011C20.126 20 21.09 18.3618 20.3494 17.0287L13.7484 5.147C12.9864 3.77538 11.0138 3.77538 10.2518 5.147Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <span class="text">Selecteer hieronder het jaar waarmee je aan de slag wil. Het is mogelijk om een periode te selecteren tot twee jaar terug en één jaar vooruit.</span>
            </div>
            <div class="period" v-for="Period in Periods" :key="Period.Year" @click.prevent.self="ActivePeriod = Period, this.$root.Vibrate()" :class="{ active: ActivePeriod == Period || (Active && Active == Period.Identifier && !ActivePeriod) }">
                <span class="radio"></span>
                <span class="year">{{ Period.Year }} <span class="active" v-if="Active && Active == Period.Identifier">Actief</span></span>
                <span class="description" v-if="!Period.Identifier">Selecteer {{ Period.Year }} om deze periode aan te schaffen.</span>
                <span class="description" v-else>Selecteer {{ Period.Year }} om deze periode aan te activeren.</span>
            </div>
            <span class="button" @click="SetPeriod(ActivePeriod), this.$root.Vibrate()">Activeer periode</span>

            <span class="title">Facturen</span>
            <div class="period" v-for="Invoice in Invoices" :key="Invoice.Identifier">
                <span class="year">Factuur {{ Invoice.Number }}</span>
                <span class="description">Aangemaakt op {{ this.$moment(Invoice.Created).format('DD-MM-YYYY') }}</span>
                <span class="button download" @click="GetInvoice(Invoice.Identifier), this.$root.Vibrate()">Download factuur</span>
            </div>
        </div>
    </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Password from 'primevue/password'
import InputOtp from 'primevue/inputotp';
import RadioButton from 'primevue/radiobutton'
import { useToast } from "vue-toastification"

const toast = useToast()

export default {
    name: 'Settings',

    components: {
        DataTable,
        Column,
        Password,
        RadioButton,
        InputOtp
    },

    data () {
        return {
            View: 'informatie',
            Client: [],
            User: [],
            Periods: [],
            Invoices: [],
            Untill: false,
            ActivePeriod: false,
            Active: localStorage.getItem('BBPT'),
            NewEmail: '',
            NewEmailCheck: '',
            NewEmailOTP: false,
            NewEmailOTPCode: '',
            OldPassword: '',
            NewPassword: '',
            NewPasswordCheck: '',
            NewPasswordOTP: false,
            NewPasswordOTPCode: '',
            NewTFA: '',
            NewTFAQR: '',
            NewTFAQRCode: '',
            NewTFAOTP: false,
            NewTFAOTPCode: '',
            KvK: {
                Active: true,
                Loader: false,
                Search: '',
                Items: [],
                ID: '',
                Filters: {
                    global: {
                        value: ''
                    }
                }
            }
        }
    },

    mounted () {
        this.GetProfile()

        if(this.$route.params.tab) {
            this.View = this.$route.params.tab
        }
    },

    methods: {
        GetProfile () {
            this.$http.get('https://bouwbond.app/api/v1/auth/profile').then((response) => {                
                this.Client = response.data.client
                this.User = response.data.user
                this.Periods = response.data.periods
                this.Invoices = response.data.invoices
                this.Untill = response.data.untill

                if(!response.data.client.KVK) {
                    this.View = 'informatie'
                } else if(response.data.periods && response.data.periods.filter(e => e.Identifier).length == 0 && this.$route.params.tab == 'lidmaatschap') {
                    this.$root.SetIntro(true)
                }

                this.NewTFA = response.data.user.TFA
            })
        },

        SearchKvK () {
            if(this.KvK.Search) {
                this.$root.SetLoader(true)
                this.$http.get('https://bouwbond.app/api/v1/import/kvk', {
                    params: {
                        Search: this.KvK.Search
                    }
                }).then((response) => {
                    if(response.data.results) {
                        this.KvK.Items = response.data.results
                    } else {
                        this.KvK.Items = false

                        toast.warning("Geen resultaten gevonden", {
                            position: "top-right",
                            timeout: 2500,
                            closeOnClick: true,
                            pauseOnHover: true,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            icon: true
                        })
                    }
    
                    this.$root.SetLoader(false)
                })
            } else {
                toast.warning("Geef een zoekterm op", {
                    position: "top-right",
                    timeout: 2500,
                    closeOnClick: true,
                    pauseOnHover: true,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    icon: true
                })
            }
        },

        CompleteKvK (e) {
            this.$root.SetLoader(true)
            this.$http.get('https://bouwbond.app/api/v1/import/kvk', {
                params: {
                    ID: e.data.ID
                }
            }).then((response) => {
                this.KvK.Active = false
                this.KvK.Items = []

                this.KvK.ID = e.data.ID
                this.KvK.Date = response.data.kvk.Date
                this.KvK.Name = response.data.kvk.Name
                this.KvK.Street = response.data.kvk.Street
                this.KvK.HouseNumber = response.data.kvk.HouseNumber
                this.KvK.Postcode = response.data.kvk.Postcode
                this.KvK.City = response.data.kvk.City
                this.KvK.Country = response.data.kvk.Country
                this.KvK.SBI = response.data.kvk.SBI

                this.$root.SetLoader(false)
            })
        },

        ConnectKvK () {
            this.$root.SetLoader(true)
            this.$http.patch('https://bouwbond.app/api/v1/import/kvk', {
                KvK: this.KvK,
                User: this.User
            }).then(() => {
                this.$router.push('/')

                this.$root.SetLoader(false)
            })
        },

        Order () {
            this.$root.SetLoader(true)
            this.$http.get(`https://bouwbond.app/api/v1/auth/order`).then((response) => {
                if(response.data.link) {
                    location.href = response.data.link
                } else {
                    toast.warning("Er is iets fout gegaan. Probeer het opnieuw.", {
                        position: "top-right",
                        timeout: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        icon: true
                    })
                }
            })
        },

        SetPeriod (e) {
            this.$root.SetLoader(true)
            this.$http.get(`https://bouwbond.app/api/v1/auth/period/${e.Year}`).then((response) => {
                this.$root.SetLoader(false)

                if(response.data.active) {
                    this.Active = response.data.active
                    this.ActivePeriod = false 

                    this.$root.$refs.Menu.Year = e.Year

                    localStorage.setItem('BBY', e.Year)
                } else {
                    toast.warning("Er is iets fout gegaan. Probeer het opnieuw.", {
                        position: "top-right",
                        timeout: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        icon: true
                    })
                }
            })
        },

        GetInvoice (e) {
            this.$root.SetLoader(true)
            this.$http.get(`https://bouwbond.app/api/v1/auth/invoice/${e}`).then((response) => {                
                this.$root.SetLoader(false)

                if(response.data.base64) {
                    var byteCharacters = atob(response.data.base64)
                    var byteNumbers = new Array(byteCharacters.length)
                    
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i)
                    }

                    var byteArray = new Uint8Array(byteNumbers)
                    var file = new Blob([byteArray], { type: 'application/pdf;base64' })
                    var fileURL = URL.createObjectURL(file)

                    window.open(fileURL)
                } else {
                    toast.warning("Er is iets fout gegaan. Probeer het opnieuw.", {
                        position: "top-right",
                        timeout: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        icon: true
                    })
                }
            })
        },

        ChangeEmail () {
            this.$http.patch(`https://bouwbond.app/api/v1/auth`, {
                Email: this.NewEmailCheck,
                OTP: this.NewEmailOTPCode
            }).then((response) => {
                if(response.data.otp) {
                    this.NewEmailOTP = true
                } else if(response.data.otp_reset) {
                    this.NewEmailOTPCode = ''

                    setTimeout(() => {
                        document.querySelector('.p-inputotp .p-inputotp-input').focus()
                    }, 100)

                    toast.warning("Onjuiste code. Probeer het opnieuw.", {
                        position: "top-right",
                        timeout: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        icon: true
                    })
                } else if(response.data.email) {
                    this.User.EmailChange = false
                    this.NewEmail = ''
                    this.NewEmailCheck = ''
                    this.NewEmailOTP = false
                    this.NewEmailOTPCode = ''

                    this.GetProfile()

                    toast.success("Je e-mailadres is gewijzigd.", {
                        position: "top-right",
                        timeout: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        icon: true
                    })
                }
            })
        },

        ChangePassword () {
            this.$http.patch(`https://bouwbond.app/api/v1/auth`, {
                OldPassword: this.OldPassword,
                NewPassword: this.NewPassword,
                OTP: this.NewPasswordOTPCode
            }).then((response) => {
                if(response.data.otp) {
                    this.NewPasswordOTP = true
                } else if(response.data.otp_reset) {
                    this.NewPasswordOTPCode = ''

                    setTimeout(() => {
                        document.querySelector('.p-inputotp .p-inputotp-input').focus()
                    }, 100)

                    toast.warning("Onjuiste code. Probeer het opnieuw.", {
                        position: "top-right",
                        timeout: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        icon: true
                    })
                } else if(response.data.password) {
                    this.User.PasswordChange = false
                    this.OldPassword = ''
                    this.NewPassword = ''
                    this.NewPasswordCheck = ''
                    this.NewPasswordOTP = false
                    this.NewPasswordOTPCode = ''

                    toast.success("Je wachtwoord is gewijzigd.", {
                        position: "top-right",
                        timeout: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        icon: true
                    })
                }
            })
        },

        ChangeOTP () {
            this.$http.patch(`https://bouwbond.app/api/v1/auth`, {
                TFAMethod: this.NewTFA,
                OTP: this.NewTFAOTPCode
            }).then((response) => {
                if(response.data.otp) {
                    this.NewTFAOTP = true
                }
                
                if(response.data.otp_reset) {
                    this.NewPasswordOTPCode = ''

                    setTimeout(() => {
                        document.querySelector('.p-inputotp .p-inputotp-input').focus()
                    }, 100)

                    toast.warning("Onjuiste code. Probeer het opnieuw.", {
                        position: "top-right",
                        timeout: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        icon: true
                    })
                }
                
                if(response.data.qr) {
                    this.NewTFAQR = response.data.qr
                }
                
                if(response.data.tfa) {
                    this.NewOTPMethod = ''
                    this.NewOTPCode = ''

                    toast.success("Tweestapsverificatie is gewijzigd.", {
                        position: "top-right",
                        timeout: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        icon: true
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
.settings .settings-menu {
    display: flex;
    padding: 50px 0 0 0;
}

.settings .settings-menu .menu-item {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 8px #cdf0ff;
    cursor: pointer;
}

.settings .settings-menu .menu-item.active {
    font-weight: 600;
    border-bottom: solid 8px #009fe3;
}

.settings .tab {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 50px 0;
}

.settings .tab .title {
    font-size: 20px;
    font-weight: 600;
}

.settings .tab .group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.settings .tab .group.column {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.settings .tab .group.otp {
    margin: 25px 0 0 0
}

.settings .tab .group .subtitle {
    width: 40%;
    display: flex;
    align-items: center;
    gap: 5px;
}

.settings .tab .group.otp .subtitle, .settings .tab .group.column .subtitle {
    width: 100%;
    font-weight: 600;
}

.settings .tab .group .description {
    width: 100%;
    margin: 0 0 15px 0;
}

.settings .tab .group .subtitle .tip {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #009fe3;
    padding: 2.5px 10px;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
    border-radius: 5px;
}

.settings .tab .group .subtitle .active {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3cb371;
    padding: 2.5px 10px;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
    border-radius: 5px;
}

.settings .tab .group input {
    width: 60%;
    background: #fff;
    padding: 10px 15px;
    border: solid 1px #e7eaf5;
    border-radius: 5px;
}

.settings .tab .group input:disabled {
    color: #a9a9a9;
}

.settings .tab .group .edit {
    position: absolute;
    top: calc(50% - 12px);
    right: 12px;
    width: 24px;
    height: 24px;
    stroke: #333;
    cursor: pointer;
}

.settings .tab .group .toggle {
    width: 60%;
}

.settings .tab .group .toggle .toggle-item {
    width: 50%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.settings .tab .group .toggle .toggle-item.active {
    background: #009fe3;
    color: #fff;
    font-weight: 600;
}

.settings .tab .group:deep(.p-password) {
    width: 60%;
}

.settings .tab .group:deep(.p-inputtext) {
    width: 100%;
    border: solid 1px #e7eaf5;
}

.settings .tab .group:deep(.p-inputotp) {
    width: 60%;
    max-width: 350px;
}

.settings .tab .create {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    padding: 35px 25px 50px 25px;
    background: #fff;
    border: solid 1px #eff0f6;
    border-radius: 5px;
}

.settings .tab .create .title {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    font-size: 30px;
}

.settings .tab .create .description {
    width: 100%;
    margin: -25px 0 0 0;
}

.settings .tab .create .search {
    width: 100%;
    padding: 0 15px;
    height: 50px;
    background: #fafafa;
    border: 0;
    border-radius: 5px;
}

.settings .tab .create .group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.settings .tab .create .group.half {
    width: calc(50% - 12.5px);
}

.settings .tab .create .group.third {
    width: calc(33% - 14px);
}

.settings .tab .create .group .subtitle {
    width: 100%;
    font-weight: 600;
}

.settings .tab .create .group input {
    width: 100%;
    padding: 0 15px;
    height: 50px;
    background: #fafafa;
    border: 0;
    border-radius: 5px;
}

.settings .tab .create .group button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    margin: 0 0 0 auto;
    background: #ff8c00;
    color: #fff;
    font-weight: 600;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
}

.settings .tab .create .group button.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.settings .tab .create:deep(.p-datatable) {
    width: 100%;
}

.settings .tab .change {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 25px;
    background: #eff0f4;
    border-radius: 5px;
}

.settings .tab .change .save {
    width: fit-content;
    margin: 0 0 0 auto;
    padding: 10px 25px;
    background: #009fe3;
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
}

.settings .tab .save-otp {
    width: fit-content;
    padding: 10px 25px;
    background: #009fe3;
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
}

.settings .tab .marginTop {
    margin: 50px 0 0 0;
}

.settings .tab .activate {
    display: flex;
    align-items: center;
    background: #fff797;
    border: solid 1px #ffeb7b;
    border-radius: 10px;
}

.settings .tab .activate svg {
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
    margin: 0 0 0 25px;
    stroke: #ffeb7b;
}

.settings .tab .activate .text {
    padding: 25px;
}

.settings .tab .activate .text b {
    font-weight: 700;
}

.settings .tab .period {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 25px 25px 25px 50px;
    background: #fff;
    border: solid 2px #eff0f6;
    border-radius: 10px;
    cursor: pointer;
}

.settings .tab .period.active {
    border-color: #009fe3;
}

.settings .tab .period .radio {
    position: absolute;
    top: 30px;
    left: 15px;
    width: 25px;
    height: 25px;
    background: #cfcfcf;
    border: solid 3px #fff;
    outline: solid 1px #cfcfcf;
    border-radius: 50%;
}

.settings .tab .period.active .radio {
    background: #009fe3;
    outline: solid 3px #009fe3;
}

.settings .tab .period .year {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 24px;
    font-weight: 600;
    pointer-events: none;
}

.settings .tab .period .year .active {
    padding: 2.5px 10px;
    font-size: 12px;
    font-weight: 600;
    background: #009fe3;
    color: #fff;
    border-radius: 10px;
}

.settings .tab .period .description {
    pointer-events: none;
}

.settings .tab .period .prices {
    position: absolute;
    top: 35px;
    right: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    pointer-events: none;
}

.settings .tab .period .prices .price {
    font-size: 24px;
    line-height: 24px;
    opacity: 0.5;
}

.settings .tab .period .prices .sale {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
}

.settings .tab .period .invoice {
    position: absolute;
    top: 25px;
    right: 25px;
    padding: 5px 15px;
    background: orange;
    color: #fff;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}

.settings .tab .button {
    margin: 25px 0 0 auto;
    padding: 15px 25px;
    background: #009fe3;
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
}

.settings .tab .button.download {
    width: fit-content;
    margin: 15px 0 0 0;
}





@media only screen and (max-width: 1000px) {
    .settings .tab .group {
        align-items: flex-start;
        flex-direction: column;
    }

    .settings .settings-menu {
        flex-direction: column;
        gap: 25px;
    }

    .settings .settings-menu .menu-item {
        justify-content: flex-start;
        border-width: 5px !important;
    }

    .settings .tab .group .subtitle,
    .settings .tab .group input {
        width: 100%;
    }

    .settings .tab .group .edit {
        top: calc(50% - 0px);
    }

    .settings .tab .period {
        gap: 15px;
    }

    .settings .tab .period .prices {
        position: unset;
        align-items: flex-start;
    }

    .settings .tab .period .prices .price,
    .settings .tab .period .prices .sale {
        font-size: unset;
    }

    .settings .tab .period .invoice {
        position: unset;
        text-align: center;
    }

    .settings .tab .button {
        width: 100%;
        text-align: center;
    }
}
</style>